
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
                        <!-- <div v-if="user.user_role!='viewer'"> -->

            <form @submit.prevent="updateUsers" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_name" type="text" :label="$store.getters.language.data.users.user_name" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.firebase_uid" type="textarea" :label="$store.getters.language.data.users.firebase_uid" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="users.user_email" type="textarea" :label="$store.getters.language.data.users.user_email" 
                            class="mx-1"  filled outlined dense required disabled>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-autocomplete class="mx-1" clearable  :items="employees" v-model="users.employee_id" dense  filled outlined  item-text="employee_name"
                                    item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
                        </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.users.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
                        <!-- </div> -->
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/users.request.js'
    import Emrequests from './../../requests/employees.request.js'
    export default {
        data() {
            return {
                users: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    // employees(){
                    //     return this.$store.getters.employees_list
                    // },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.gerEmployees()
        },
        methods: {
            getOneUsers() {
                this.loading = true
                requests.getOneUsers(this.id).then(r =>{
                    if (r.status == 200) {
                        this.users = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Users',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Users',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            gerEmployees() {
                Emrequests.getAllEmployees().then(r => {
                    if (r.status == 200) {
                        this.employees = r.data.rows
                                    this.getOneUsers()

                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Employees',
                            color: 'error'
                        }
                    }
                })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Employees',
                            color: 'error'
                        }
                    })
            },
            updateUsers() {
                this.loading = true
                delete this.users.user_role
                requests.updateUsers(this.id,this.users).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Users Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    